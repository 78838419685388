import { ReactNode } from 'react';
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';

export default function Introduce() {
  return (
    <Box bg={useColorModeValue('white' ,'gray.800')} position={'relative'}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: 'none', lg: 'flex' }}
        backgroundImage="url('/templates/stats-grid-with-image.png')"
        backgroundSize={'cover'}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position={'absolute'}
        width={'50%'}
        insetY={0}
        right={0}>
        
      </Flex>
      <Container maxW={'7xl'} zIndex={10} position={'relative'}>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Stack
            flex={1}
            color={'gray.400'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 20, xl: 30 }}>
            <Box mb={{ base: 8, md: 10 }}>
              <Text
                fontFamily={'heading'}
                fontWeight={700}
                textTransform={'uppercase'}
                mb={3}
                fontSize={'xl'}
                color={'gray.500'}>
                BE-UNIQUE
              </Text>
              <Heading
                color={useColorModeValue('gray.800', 'white')}
                mb={5}
                fontSize={{ base: '3xl', md: '5xl' }}>
                새로운 개념의 클라우드
              </Heading>
              <Text fontSize={'xl'} color={'gray.400'}>
                auchive는 새로운 개념의 도입을 통해 클라우드 서비스를 다시금 재구성합니다. 기존의 클라우드에서 느낄 수 있는 문제점을 사용자의 입장에서 바라보고, 한단계 더 발전한 환경을 여러분들께 제공합니다.
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} color={useColorModeValue('gray.800', 'white')}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={'heading'}
                    fontSize={'3xl'}
                    mb={3}>
                    {stat.title}
                  </Text>
                  <Text fontSize={'xl'} color={'gray.400'}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container>
    </Box>
  );
}

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={'span'} fontWeight={700} color={useColorModeValue('gray.800', 'white')}>
    {children}
  </Text>
);

const stats = [
  {
    title: '∞+',
    content: (
      <>
        <StatsText>언제든지 확장 가능한</StatsText> 유연성 있는 클라우드 스토리지
      </>
    ),
  },
  {
    title: 'Accessibility',
    content: (
      <>
        <StatsText>항시 접근 가능한</StatsText> 온라인 스토리지
      </>
    ),
  },
  {
    title: 'Conveniently',
    content: (
      <>
        <StatsText>누구나 조작할 수 있는</StatsText> 쉽고 빠른 소프트웨어
      </>
    ),
  },
  {
    title: '24/7',
    content: (
      <>
        <StatsText>365일 가동되는</StatsText> 고객 지원 센터
      </>
    ),
  }
];