import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  theme,
  Flex,
  Heading,
  Input,
  Button,
  Stack,
  chakra,
  FormControl,
  Checkbox,
  FormLabel,
  useColorModeValue
} from "@chakra-ui/react"
import { FaUserAlt, FaLock } from 'react-icons/fa'
import { useColorMode } from "@chakra-ui/color-mode";
import { loginWithInfo } from '../../Utils/auth';
import toast from 'react-hot-toast';

import { settings } from "cluster";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);



const LoginPage: React.FC = () => {
  //useState as empty state
  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [plainPwd, setPlainPwd] = useState("");
  const [showClick, setShowClick] = useState(false);
  const navigate = useNavigate();

  const onIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };
  const onPwdChange = (e: any) => {
    setPwd(e.target.value);
    setPlainPwd(e.target.value);
  };
  const handleShowClick = (e: any) => {
    setShowClick(e.target.checked);
  };

  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const login = async() => {
    if ( id === "" || pwd === "" ) {
      toast.error("모든 정보를 입력해주세요");
      return;
    } else {
      if (await loginWithInfo({ id: id, pwd: pwd })) navigate('/');
    }
  }

  const onKeyPress = (e: any) => {
    if ( e.key == 'Enter' ) {
      login();
    }
  }

    return (
        <>
              <Flex
          minH={'100vh'}
          align={'center'}
          justify={'center'}>
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>로그인</Heading>
              <Text fontSize={'lg'}>
                하나의 계정으로 모든 클라우드 서비스를
              </Text>
            </Stack>
            <Box
              rounded={'lg'}
              boxShadow="0 10px 20px 0 rgba(0,0,0,.05)"
              p={8}>
              <Stack spacing={4}>
                <FormControl id="id">
                  <FormLabel>아이디</FormLabel>
                  <Input type="id" onChange={onIdChange}/>
                </FormControl>
                <FormControl id="password">
                  <FormLabel>비밀번호</FormLabel>
                  <Input type="password" onChange={onPwdChange} onKeyPress={onKeyPress}/>
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    justify={'space-between'}>
                    <Checkbox>자동 로그인</Checkbox>
                    <Link color={'blue.400'}>비밀번호를 잊어버렸나요?</Link>
                  </Stack>
                  <Button
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="button"
                    onClick={() => login()}
                    >
                    로그인
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
    </>
  );
};

export default LoginPage;