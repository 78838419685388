import { chakra } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from '../Pages/auth/Login';
import RegisterPage from '../Pages/auth/Register';
import CloudPage from '../Pages/Cloud';
import IndexPage from '../Pages/index';
import UploadPage from '../Pages/cloud/Upload';
import LoadingRoutes from './loadingRoutes';
import DetailGallery from '../Components/Gallery/DetailGallery';





const Router : React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/auth/login" element={<LoginPage />} />
                <Route path="/auth/register" element={<RegisterPage />} />
                <Route path="/" element={<IndexPage />} />
                <Route path="/cloud" element={<CloudPage />} />
                <Route path="/cloud/detail/:id" element={<><div style={{ inset: "1px", position: "fixed", filter: "blur(5px)" }} ><CloudPage /></div> <DetailGallery /> </>} />
                <Route path="/cloud/upload" element={<UploadPage />} />'
                
                <Route path="/*" element={<span>404</span>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;