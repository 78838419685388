import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Badge,
  Image,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  useColorModeValue,
  FormLabel,
  Checkbox
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "../../ColorModeSwitcher"
import { IRegister } from '../../Interfaces/Auth';
import { clearTokens, RegisterWithInfo } from '../../Utils/auth';
import { clearData } from '../../Utils/user';
import toast from 'react-hot-toast';

import { FaUserAlt, FaLock } from "react-icons/fa";
import { settings } from "cluster";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const RegisterPage: React.FC = () => {
  //useState as empty state
  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [plainPwd, setPlainPwd] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showClick, setShowClick] = useState(false);
  const navigate = useNavigate();

  const onIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };
  const onPwdChange = (e: any) => {
    setPwd(e.target.value);
    setPlainPwd(e.target.value);
  };
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleShowClick = (e: any) => {
    setShowClick(e.target.checked);
  };

  const register = async() => {
    if(id === "" || pwd === "" || name === "" || email === "") {
      toast.error("모든 정보를 입력해주세요");
      return;
    } else {
      if (await RegisterWithInfo({ id:id, pwd:pwd, name:name, email:email, terms:true, privacy:true })) navigate('/');
    }
  }

    return (
      <>
        <Flex
    minH={'100vh'}
    align={'center'}
    justify={'center'}
    bg={useColorModeValue('gray.50', 'gray.800')}>
    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
      <Stack align={'center'}>
        <Heading fontSize={'4xl'}>회원가입</Heading>
        <Text fontSize={'lg'} color={'gray.600'}>
          하나의 계정으로 모든 클라우드 서비스를
        </Text>
      </Stack>
      <Box
        rounded={'lg'}
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow={'lg'}
        p={8}>
        <Stack spacing={4}>
          <FormControl id="name">
            <FormLabel>이름</FormLabel>
            <Input type="name" onChange={onNameChange}/>
          </FormControl>
          <FormControl id="email">
            <FormLabel>이메일</FormLabel>
            <Input type="email" onChange={onEmailChange}/>
          </FormControl>
          <FormControl id="id">
            <FormLabel>아이디</FormLabel>
            <Input type="id" onChange={onIdChange}/>
          </FormControl>
          <FormControl id="password">
            <FormLabel>비밀번호</FormLabel>
            <Input type="password" onChange={onPwdChange}/>
          </FormControl>
          <Stack spacing={10}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={'start'}
              justify={'space-between'}>
            </Stack>
            <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              type="button"
              onClick={() => register()}>
              회원가입
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  </Flex>
  </>
  );
};

export default RegisterPage;