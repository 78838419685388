import { cookie } from './cookie'
import { IUser } from '../Interfaces/User'
import { getAccessToken } from './auth';
import { axi, errMessages } from './api';
import toast from 'react-hot-toast';

export const saveData = async (data: IUser) => {
    cookie.set("USER_INFO", data, { path: '/' });
};

export const saveLink = async (data: string) => {
    cookie.set("USER_LINK", data, { path: '/' });
};

export const clearData = () => {
    cookie.remove("USER_INFO", { path: '/' });
};

export const clearLink = () => {
    cookie.remove("USER_LINK", { path: '/' });
};

export const getLocalData = () => {
    return cookie.get("USER_INFO");
};

export const getLocalLinks = () => {
    return cookie.get("USER_LINK");
};

export const fetchData = async () => {
    const res = await axi.post('/refresh', {}, {headers: {Authorization : `Bearer ${getAccessToken()}`}});
    if(res.data.code === 200) {
        saveData(res.data.user);
        return true;
    }
    else {
        toast.error(errMessages[res.data.msg]);
        return false;
    }
};

export const fetchLinks = async () => {
    const res = await axi.post('/common/links');
    if(res.data.code === 200) {
        saveLink(res.data.data);
        return true;
    }
    else {
        toast.error(errMessages[res.data.msg]);
        return false;
    }
};

export const fetchAuthLinks = async () => {
    const res = await axi.post('/common/authlinks',{}, {headers: {Authorization : `Bearer ${getAccessToken()}`}});
    if(res.data.code === 200) {
        saveLink(res.data.data);
        return true;
    }
    else {
        toast.error(errMessages[res.data.msg]);
        return false;
    }
};

export const getMyData = async () => {
    const data = getLocalData();
    if (data) return data;
    else {
        const flag = await fetchData();
        if (flag) return getLocalData();
        else return false;
    }
};
  
export const getMyLink = async () => {
    const data = getLocalLinks();
    if (data) return data;
    else {
        const flag = await fetchLinks();
        if (flag) return getLocalLinks();
        else return false;
    }
};