import { AspectRatio, Image } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import React from 'react';
import DetailGallery from "./DetailGallery";
import { useNavigate } from "react-router-dom";
import { getFileID } from "../../Utils/file";

Gallery.propTypes = {
    src: PropTypes.string.isRequired,
};



function Gallery(props: any) {
    const navigate = useNavigate();
    const id = getFileID(props.src);


    return (
        <>  
        <AspectRatio maxW="10%" maxH="10%" ratio={1/1} w="10%">
                <Image loading="lazy" src={props.src} onClick={() => {navigate(`/cloud/detail/${id}`)}} />
        </AspectRatio>
        </>

    );
}



export default React.memo(Gallery);