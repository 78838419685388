import { useColorMode } from '@chakra-ui/color-mode'
import { Flex, Heading, Spacer, IconButton } from "@chakra-ui/react";
import { useMediaQuery } from '@chakra-ui/media-query';
import { FaSun, FaMoon } from 'react-icons/fa';
import { AiOutlineCloud, AiOutlineCloudUpload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

CloudNavigation.propTypes = {
    isUploadPage: PropTypes.bool
};


function CloudNavigation({ isUploadPage }: any) {
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();
    const isDark = colorMode === "dark";
    
    const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");


    return (
        <Flex w="100%" alignItems="baseline">
            <div>
                <Heading ml="8" size="md" fontWeight='semibold' color="cyan.500">twarchive</Heading>
            </div>
            <Spacer></Spacer>
            { isUploadPage ? (<IconButton aria-label="" ml={2} icon={<AiOutlineCloud />} onClick={() => {navigate('/cloud');}}></IconButton>) : (<IconButton aria-label="" ml={2} icon={<AiOutlineCloudUpload />} onClick={() => {navigate('/cloud/upload');}}></IconButton>)}
            <IconButton aria-label="" ml={2} icon={isDark ? <FaSun /> : <FaMoon />} onClick={toggleColorMode}></IconButton>
        </Flex>
    )
}

export default React.memo(CloudNavigation);