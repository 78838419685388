import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Badge,
  Image,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  useColorModeValue,
  FormLabel,
  Checkbox,
  IconButton,
  Divider,
  useColorMode
} from "@chakra-ui/react"
import { FaUserAlt, FaLock } from "react-icons/fa";
import Sidebar from '../../Components/Sidebar';
import { FiMenu } from 'react-icons/fi';
import { getMyData } from "../../Utils/user";
import CloudNavigation from "../../Components/Navigation/CloudNavigation";
import DragDrop from "../../Components/Upload/DragDrop";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);


const UploadPage: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState('');

  getMyData().then(result => {
    setUserName(result.name);
    setUserType(result.type);
  })

  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";

  return (
      <>
    <VStack p={2} borderRadius="0 10px 20px 0 rgba(0,0,0,.05)" >
    <CloudNavigation isUploadPage/>
    </VStack>
    <Divider />
    <Flex w="100%" justifyContent="center">
      <Flex
        alignItems="center"
      >
        <DragDrop />
      </Flex>
    </Flex>

    
    </>
  );

};

export default UploadPage;