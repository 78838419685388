import { Divider } from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import './menu.scss';


function UseCustomRightClick() {
    // 메뉴
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);

    const handleContextMenu = useCallback(
            (event) => {
                event.preventDefault()
                setAnchorPoint({ x: event.pageX, y: event.pageY });
                setShow(true);
            },
            [setAnchorPoint, setShow]
            );

    const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

    useEffect(() => { // 마운트
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        } // 언마운트
    });

    return (
        <>
        {show ? (
        <ul
          className="menu"
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x
          }}
        >
          <li>Share to..</li>
          <li>Cut</li>
          <li>Copy</li>
          <li>Paste</li>
          <Divider />
          <li>Refresh</li>
          <li>Exit</li>
        </ul>
      ) : (
        <> </>
    )}
    </>

    );
}

export default React.memo(UseCustomRightClick);