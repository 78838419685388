import React from "react"
import Router from '../src/routes';
import { Toaster } from 'react-hot-toast';
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./chakraCfg";


export function App () {
  console.log("App appear");
  return (
    <>
      <Toaster position="top-center" reverseOrder={false}/>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </>
  );
}
