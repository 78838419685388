import {
    ChangeEvent,
    useCallback,
    useRef,
    useState,
    useEffect,
  } from "react";
import { IconButton, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
  import "./DragDrop.scss";
  import axios from "axios";
  import { axi, errMessages } from "../../Utils/api";
  import { getAccessToken } from "../../Utils/auth";
  import toast from "react-hot-toast";
  
  interface IFileTypes {
    id: number;
    object: File;
  }
  
  const DragDrop = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const isDark = colorMode === "dark";

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [files, setFiles] = useState<IFileTypes[]>([]);
  
    const dragRef = useRef<HTMLLabelElement | null>(null);
    const fileId = useRef<number>(0);
  
    const onChangeFiles = useCallback(
      (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFiles: File[] = [];
        let tempFiles: IFileTypes[] = files;
  
        if (e.type === "drop") {
          selectFiles = e.dataTransfer.files;
        } else {
          selectFiles = e.target.files;
        }
  
        for (const file of selectFiles) {
          tempFiles = [
            ...tempFiles,
            {
              id: fileId.current++,
              object: file
            }
          ];
        }
  
        setFiles(tempFiles);
      },
      [files]
    );
  
    const handleFilterFile = useCallback(
      (id: number): void => {
        setFiles(files.filter((file: IFileTypes) => file.id !== id));
      },
      [files]
    );
  
    const handleDragIn = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();
    }, []);
  
    const handleDragOut = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();
  
      setIsDragging(false);
    }, []);
  
    const handleDragOver = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();
  
      if (e.dataTransfer!.files) {
        setIsDragging(true);
      }
    }, []);
  
    const handleDrop = useCallback(
      (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
  
        onChangeFiles(e);
        setIsDragging(false);
      },
      [onChangeFiles]
    );
  
    const initDragEvents = useCallback((): void => {
      if (dragRef.current !== null) {
        dragRef.current.addEventListener("dragenter", handleDragIn);
        dragRef.current.addEventListener("dragleave", handleDragOut);
        dragRef.current.addEventListener("dragover", handleDragOver);
        dragRef.current.addEventListener("drop", handleDrop);
      }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  
    const resetDragEvents = useCallback((): void => {
      if (dragRef.current !== null) {
        dragRef.current.removeEventListener("dragenter", handleDragIn);
        dragRef.current.removeEventListener("dragleave", handleDragOut);
        dragRef.current.removeEventListener("dragover", handleDragOver);
        dragRef.current.removeEventListener("drop", handleDrop);
      }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  
    useEffect(() => {
      initDragEvents();
  
      return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);

    const confirmUpload = async(uploadID: string, fileID: string, name: string, size: string, tags:string[]) => {

        const headers = {
            "Authorization" : `Bearer ${getAccessToken()}`
        }
        const data = {
            uploadID: uploadID,
            fileID: fileID,
            name: name,
            size: size,
            tags: tags,
        }

        const res = await axi.post('/file/upload/confirm', data, {headers});
        console.log(res);
        if ( res.data.code === 200 ) return true
        else return false
    }
    
    const upload = async(count: number) => { // count는 파일 개수 (0부터 시작하지 않음)
        const authtoast = toast.loading('파일 서버와 통신중...');

        let uploadURL: string = ''
        let uploadID: string = ''

        const headers = {
            "Authorization" : `Bearer ${getAccessToken()}`
        }
        
        try {
            const res = await axi.post('/file/upload/geturi', {}, {headers});
            console.log(res.data);

            if ( res.data.code === 200 ) {
                    console.log("upload code 200")
                    uploadURL = res.data.uploadURL;
                    uploadID = res.data.uploadID;
                } else {
                    console.log("failed")
                    console.log(res.data);
                    toast.error(errMessages[res.data.msg], { id: authtoast });
                    return;
                }
        } catch (err) {
            console.log(err);
            toast.error("서버와 통신에 실패했습니다.", { id: authtoast });
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", files[(count-1)].object);
            
            const ress = await axios.post(uploadURL, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            const fileID = ress.data.result.id;
            
            if ( ress.status === 200 ) {
                await confirmUpload(uploadID, fileID, files[(count-1)].object.name, String(files[(count-1)].object.size), [""]);
                toast.success('파일을 성공적으로 업로드했습니다.', { id: authtoast });
            }
            else toast.error('업로드를 실패하였습니다.', { id: authtoast });

        } catch (err) {
            toast.error('CF 서버와 통신에 실패하였습니다.', { id: authtoast });
            toast.error(String(err));
        }
    }

    const uploadAll = async() => {
        if ( files.length === 0 ) return toast.error('업로드할 파일이 없습니다.');

        for (let i=0; i<files.length; i++) {
            await upload(i+1);
        }
    }

  
    return (
      <div className="DragDrop">
        <input
          type="file"
          id="fileUpload"
          style={{ display: "none" }}
          multiple={true}
          onChange={onChangeFiles}
        />
  
        <label
          className={isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
          htmlFor="fileUpload"
          ref={dragRef}
        >
          <div>파일 첨부</div>
        </label>
  
        <div className="DragDrop-Files">
          {files.length > 0 &&
            files.map((file: IFileTypes) => {
              const {
                id,
                object: { name }
              } = file;
  
              return (
                  
                <div key={id}>
                  <div style={{ }}>{name}</div>
                  <div
                    className="DragDrop-Files-Filter"
                    onClick={() => handleFilterFile(id)}
                  >
                    X
                  </div>
                </div>
              );
            })}
        </div>

        <IconButton aria-label='Upload' size="lg" icon={<AiOutlineCloudUpload />} onClick={() => uploadAll()}></IconButton>
      </div>
    );
  };
  
  export default DragDrop;