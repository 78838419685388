import { cookie } from './cookie'
import toast from 'react-hot-toast';
import { ILogin, IRegister } from '../Interfaces/Auth';
import { axi, errMessages } from './api';
import { fetchData } from './user';

export const getAccessToken = (): string | undefined => {
    return cookie.get("ACCESS_TOKEN");
};

export const clearTokens = () => {
    cookie.remove("ACCESS_TOKEN", { path: '/' });
};

export const loginWithInfo = async (data:ILogin) => {
  const authtoast = toast.loading('인증 서버와 통신중...');
  try{
    const res = await axi.post('/auth/login', data);
    if(res.data.code === 200) {
      cookie.set("ACCESS_TOKEN", res.data.token, { path: '/' });
      await fetchData();
      toast.success('로그인 성공', { id: authtoast });
      return true;
    }
    else {
      toast.error(errMessages[res.data.msg], { id: authtoast });
      return false;
    }
  }
  catch (err){
    console.log(err);
    return false;
  };
}

export const RegisterWithInfo = async (data:IRegister) => {
  const authtoast = toast.loading('인증 서버와 통신중...');
  try{
    const res = await axi.post('/auth/register', data);
    if(res.data.code === 200) {
      cookie.set("ACCESS_TOKEN", res.data.token, { path: '/' });
      await fetchData();
      toast.success('회원가입 성공', { id: authtoast });
      return true;
    }
    else {
      toast.error(errMessages[res.data.msg], { id: authtoast });
      return false;
    }
  }
  catch (err){
    console.log(err);
    return false;
  };
};