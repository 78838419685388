import { AspectRatio, Image, Box, useColorModeValue, IconButton } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { getFileID } from "../../Utils/file";
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";

DetailGallery.propTypes = {
    src: PropTypes.string,
};



function DetailGallery(props: any) {
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [src, setSrc] = useState('');
    console.log(location.pathname.split('/'))

    useEffect(() => {
        setId(location.pathname.split('/')[3]);
        setSrc("https://twice.wiki/cdn-cgi/imagedelivery/-_lwGFEGrwMa60g-Dsebww/" + id + "/public")
    }, [id, src])
    

    return (
        <>
        <Box
        backgroundColor={useColorModeValue('gray.100', 'gray.600')}
        inset="40px"
        padding="10px"
        borderRadius="10px" 
        position="fixed" 
        zIndex="1"
        >
            <IconButton aria-label="X" icon={<AiOutlineClose />} position="absolute" top="10px" right="10px" zIndex="2"
            onClick={() => navigate('/cloud')}
             />
            <AspectRatio maxW="100%" maxH="100%" ratio={1/1} w="100%">
                    <Image style={{ objectFit: "contain" }} src={src} />
            </AspectRatio>
            
        </Box>
        </>

    );
}



export default React.memo(DetailGallery);