import { useColorMode } from '@chakra-ui/color-mode'
import { Flex, Box, Spacer, IconButton } from "@chakra-ui/react";
import { useMediaQuery } from '@chakra-ui/media-query';
import { FaSun, FaMoon } from 'react-icons/fa'
import { FiLogIn } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import './navigation.scss';

function Navigation() {
    const navigate = useNavigate();

    const { colorMode, toggleColorMode } = useColorMode();
    const isDark = colorMode === "dark";
    

    const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");

    return (
        <Flex w="100%">
            <Box ml="4" size="md" className='navigation-logo'></Box>
            <Spacer></Spacer>
            <IconButton aria-label="" ml={2} icon={<FiLogIn />} onClick={() => {navigate('/auth/login');}}></IconButton>
            <IconButton aria-label="" ml={2} icon={isDark ? <FaSun /> : <FaMoon />} onClick={toggleColorMode}></IconButton>
        </Flex>
    )
}

export default Navigation