import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Badge,
  Image,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  useColorModeValue,
  FormLabel,
  Checkbox,
  IconButton,
  Divider,
  useColorMode
} from "@chakra-ui/react"
import { FaUserAlt, FaLock } from "react-icons/fa";
import Sidebar from '../Components/Sidebar';
import { FiMenu } from 'react-icons/fi';
import { getMyData } from "../Utils/user";
import CloudNavigation from "../Components/Navigation/CloudNavigation";
import Gallery from "../Components/Gallery/Gallery";
import UseCustomRightClick from "../Components/Menu/CustomRightClick";
import { axi } from "../Utils/api";
import { desktop } from "../Utils/file";
import PropTypes from 'prop-types';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const CloudPage: React.FC = ({ notClickAble }: any) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState('');

  getMyData().then(result => {
    setUserName(result.name);
    setUserType(result.type);
  })

  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const [photoArray, setPhotoArray] = useState([]);

  useEffect(() => {
    console.log("이미지 불러오기");
    const getImages = async() => {
      const data1 = {
          count: "0",
          skip: "0",
      }
      const res = await axi.post('/file/get/latest', data1)
      const filesArray: [] = res.data.data
      const data2 = {
          files: filesArray
      }
      const ress = await axi.post('/file/get/uri', data2);
      const resultArray = ress.data.data;
      return resultArray;
    }
    getImages().then(result => {
      setPhotoArray(result);
    })
  }, [])




  return (
    <>
    <UseCustomRightClick />
    <VStack p={2} borderRadius="0 10px 20px 0 rgba(0,0,0,.05)" >
    <CloudNavigation isUploadPage={false} />
    </VStack>
    <Divider />
    <Flex w="100%" justifyContent="center">
      <div style={{ width: "100%", height: "100%", display: "inline-flex", flexWrap: "wrap"}}>

      {
        photoArray.map((item: any, index: any) => (
          <Gallery src={desktop(item)} key={index} />
        ))
      }
        
      </div>
        
    </Flex>
    </>
  );

};

export default React.memo(CloudPage);