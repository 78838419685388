import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Badge,
  Image,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  useColorModeValue,
  FormLabel,
  Checkbox,
  Divider
} from "@chakra-ui/react"
import toast from 'react-hot-toast';
import Header from '../Components/Header';
import Navigation from "../Components/Navigation/Navigation";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { settings } from "cluster";
import Introduce from "../Components/Introduce";
import Footer from "../Components/Footer";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);


const IndexPage: React.FC = () => {
  const navigate = useNavigate();

  function existCookie(name: any) {
    const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    if (!cookie) return false
    if (cookie) return true
    return false
  }

  const isLoggedin = existCookie("ACCESS_TOKEN");
  console.log(isLoggedin);
  
  useEffect(() => {
    if (isLoggedin) navigate("/cloud")
  });


  return (
    <>
      
                <VStack p={5}>
                    <Navigation />
                    <Header />
                    <Divider />
                    <Introduce />
                </VStack>
                <Footer />
    </>
    );

};

export default IndexPage;