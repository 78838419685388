import { useColorMode } from '@chakra-ui/color-mode'
import { Stack, Circle, Flex, Box, Text, Button, Image } from "@chakra-ui/react";
import { useMediaQuery } from '@chakra-ui/media-query';
import React from 'react'
import toast from 'react-hot-toast';

function Header() {

    const { colorMode } = useColorMode();
    const isDark = colorMode === "dark";

    const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");

    return (
        <Stack>
            <Flex direction={isNotSmallerScreen ? "row" : "column"}
                spacing="200px" p={isNotSmallerScreen ? "32" : "4"}
                alignSelf="flex-start">
                <Box mt={isNotSmallerScreen ? "0" : 16} align='flex-start'>
                    <Text fontSize={isNotSmallerScreen ? "4xl" : "3xl"} fontWeight="semibold">흩어진 것들을 한 곳에.</Text>
                    <Text fontSize={isNotSmallerScreen ? "5xl" : "4xl"} lineHeight='1.3' fontWeight="bold" bgGradient="linear(to-r, #00d1db, #706dde)" bgClip='text' >우리들의 클라우드, auchive</Text>
                    <Text color={isDark ? "gray.200" : "gray.500"} mt={3}>베타 서비스를 준비 중이에요.</Text>
                    <Button mt={8} colorScheme="blue" onClick={() =>
                        toast.error("아직 준비 중이에요. 조금만 기다려주세요.")
                    }>더 알아보기</Button>

                </Box>
            </Flex>

        </Stack>
    )
}

export default Header