export const desktop = (text: string) => {
    text = text.replace('/public', '/desktop');
    return text
};

export const getFileID = (text: string) => {
    let arr = text.split('/');
    if (arr.length === 8) {
        return arr[6]
    }
}